form {
	margin: 30px 0;
	width: 100%;

	.form-group {
		display: block;
	}

	label {
		color: $color-desmond-white;
		margin: 0 0 15px;
		display: block;
	}

	input,
	textarea {
		width: 100%;
		padding: 10px 15px;
		line-height: 1;
		background: transparent;
		border: 2px solid rgba(255, 255, 255, 0.2);
		color: #fff;
		font-size: 16px;
		margin: 0 0 30px;
		font-family: 'Arial', sans-serif;

		&:focus {
			outline: none;
			border-color: $color-desmond-white;
		}
	}

	textarea {
		height: 200px;
	}
}

.btn {
	background: $color-desmond-red;
	font-family: 'Raleway', sans-serif;
	display: inline-block;
	width: auto;
	padding: 15px 30px;
	font-weight: 600;
}
