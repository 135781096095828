footer {
  background: $color-desmond-black;
  text-align: center;
  padding: 60px;

  a {
    display: inline-block;
    margin: 0 30px;
    position: relative;
    color: $color-desmond-white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-decoration-color: $color-desmond-red;
    }

    &:after {
      content: "";
      height: 100%;
      width: 1px;
      position: absolute;
      right: -30px;
      background: $color-desmond-red;
    }

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}
