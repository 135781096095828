main.policy {
  ol,
  ul {
    list-style: disc;
    margin: 30px;
  }

  .privacy {
    max-width: 720px;
    padding: 0 30px;
    margin: 0 auto;
    width: 100%;
    align-items: start;

    h1 {
      margin: 0;
    }
  }

  p,
  li {
    text-align: left !important;
    font-size: 16px;
    margin: 0 0 15px;
    font-style: normal;
    color: #fff;
    line-height: 1.5;
  }

  strong {
    color: #fff;
    font-weight: 700;
    font-size: 21px;
    display: block;
    margin: 30px 0 15px;
  }

  a {
    font-weight: 700;
    color: #fff;
    display: inline-block;
    border-bottom: 2px solid #de0000;
    text-decoration: none;
  }
}
