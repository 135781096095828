@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600;900&family=Sahitya&display=swap");
@import "variables";
@import "reset";
@import "components";
@import "header";
@import "footer";
@import "privacy";
@import "support";

html {
  height: 100%;
}

body {
  background-repeat: no-repeat;
  background-color: $color-desmond-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    to left bottom,
    #212121 0%,
    #101010 50%,
    $color-desmond-black 100%
  );
  font-family: "Sahitya", serif;
  min-height: 100%;
}

.content {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

p {
  color: #fff;
  font-size: 24px;
  line-height: 1.5;
  font-style: italic;
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

h1 {
  max-width: 600px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center center;
  font-family: "Raleway", sans-serif;
  font-size: 61px;
  color: $color-desmond-white;
  position: relative;
  display: inline-block;
  padding: 30px 0;
  font-weight: 900;
  /*text-shadow: 0 0 30px rgba(0, 0, 0, 1);*/
  text-transform: uppercase;
  letter-spacing: -1.7px;

  svg {
    max-width: 400px;
    width: 100%;
  }
}
/*
h1::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 5px 0 6px;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  left: 133px;
  top: 70px;
}

h1::after {
  content: "";
  background: #de0000;
  position: absolute;
  left: 3px;
  bottom: 27px;
  height: 5px;
  width: 38px;
}*/

h1 span {
  color: #de0000;
}

@media screen and (max-width: 768px) {
}
