header {
	position: relative;
	top: 0;
	left: 0;
	height: 80px;
	width: 100%;
	text-align: center;

	svg {
		width: 100px;
		margin: 0 16px;
	}
}
